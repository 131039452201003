import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { format } from "date-fns";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import api from "Api";
import sotApi from "api/sotApi";
import PromptAlert from "components/PromptAlert";
import styles from "../../sourcedocs.module.scss";
import { compareStr } from "components/common/utils";
import { ApiParams } from "../constants";
import { SnackbarContext } from "context/SnackbarContext";
import { CustomPopup } from "./CustomPopUp";

const Form = () => {
  const [hideLabel, setHideLabel] = useState(true);
  const [exceptionError, setexceptionError] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [channelArray, setChannelArray] = useState(undefined);
  const [segmentArray, setSegmentArray] = useState(undefined);
  const [segmentApiResponse, setSegmentApiResponse] = useState();
  const [selectedChannel, setSelectedChannel] = useState();
  const [stateArray, setStateArray] = useState();
  const [modifiedObj, setModifiedObj] = useState({}); //new snap
  const [errorCount, setErrorCount] = useState([]); //to capture errors on onChange(not morethan x characters)
  const [isBlocking, setBlocking] = useState(false);
  const [error, setError] = useState({
    refid: "",
    channel: "",
    segment: "",
  });
  const history = useHistory();
  const { setSnackBarConfig } = useContext(SnackbarContext);
  const [newSnapState, setNewSnapState] = useState({
    refid: "",
    channel: "",
    segment: "",
    hit: 0,
    seminarflag: "",
    state: "",
    county: "",
    dominant_st_county: "",
    dominant_fips: "",
    insertion_status: "",
    budget_source: "",
    brand: "",
    publication: "",
    publication_abbreviation: "",
    nucleusndxcode: "",
    insertion_date: "",
    day_of_week: "",
    publication_days: "",
    material_deadline_range: "",
    material_due_date: "",
    insertion_format: "",
    paper_type: "",
    circulation: "",
    total_events: "",
    newspaper_specs: "",
    provider_code: "",
    publication_language: "",
    template_code: "",
    fips: "",
  });

  useEffect(() => {
    const fields = [
      "channel",
      "segment",
      "state",
      "seminarflag",
      "material_due_date",
      "insertion_date",
    ];
    setModifiedObj({ ...newSnapState });
    Object.keys(newSnapState).forEach((key) => {
      if (fields.includes(key)) {
        if (key === "channel" || key === "segment" || key === "hit") {
          setModifiedObj((prev) => ({
            ...prev,
            [key]: Number(prev?.[key]?.value) || "",
          }));
        } else if (key === "insertion_date") {
          if (newSnapState[key]) {
            setModifiedObj((prev) => ({
              ...prev,
              [key]: format(newSnapState[key], "yyyy-MM-dd"),
            }));
          }
        } else if (key === "material_due_date") {
          if (newSnapState[key] != "") {
            setModifiedObj((prev) => ({
              ...prev,
              [key]: format(newSnapState[key], "yyyy-MM-dd"),
            }));
          }
        } else
          setModifiedObj((prev) => ({ ...prev, [key]: prev?.[key]?.value || "" }));
      }
    });
  }, [newSnapState]);

  useEffect(async () => {
    setNewSnapState({ ...newSnapState, segment: "" });
    await callLoadChannelSegments(selectedChannel);
  }, [selectedChannel]);

  const callLoadChannelSegments = async (selectedChannel) => {
    try {
      if (typeof selectedChannel != "undefined") {
        const res = await sotApi[ApiParams.moduleName].loadChannelSegments({
          channel_id: selectedChannel,
        });
        if (res?.data) {
          setSegmentApiResponse(res?.data);
        }
      }
    } catch (e) {
      alert("Something went wrong");
    }
  };
  useEffect(() => {
    let temp = [];
    let data;
    if (typeof segmentApiResponse != "undefined") {
      data = Object.keys(segmentApiResponse);
      data.forEach((item) => {
        temp.push({ text: segmentApiResponse[item], value: item });
      });
    }
    setSegmentArray(temp.sort((a, b) => compareStr(a.text, b.text)));
  }, [segmentApiResponse]);

  function isInNSPFormat(input, id) {
    const pattern = /^NSP_\d+$/; // Regular expression to match NSP_<number>
    if (pattern.test(input)) {
      return true
    }
    else{
      return false
    }  
}

function formatNSP(input) {
  let parts = input.split('_'); // Split input at '_'
  if (parts.length !== 2 || parts[0] !== 'NSP' || isNaN(parts[1])) {
      throw new Error('Invalid input format'); // Handle incorrect formats
  }
  let numStr = parseInt(parts[1], 10).toString(); // Convert number part to integer and back to string
  if (numStr.length < 3) {
      numStr = numStr.padStart(3, '0'); // Ensure at least 3 digits
  }
  return `NSP_${numStr}`;
}

  useEffect(async () => {
    const body = {
      filter: [
        {
          field: "category",
          criteria: [
            {
              op: "is",
              value: "General",
            },
          ],
        },
        {
          field: "lookupname",
          criteria: [
            {
              op: "is",
              value: "State",
            },
          ],
        },
      ],
    };
    callGetStates(body);
    try {
      const res = await sotApi[ApiParams.moduleName].loadChannels();
      if (res?.data) {
        setChannelArray(res?.data.map(item => ({
          text: item.shortname,
          value: item.channel_id
        })).sort((a, b) => compareStr(a.text, b.text)));
      }
    } catch (e) {
      alert("Something went wrong!");
    }
  }, []);

  const handleChange = (e) => {
    const countFields = {
      refid: 10,
      fips: 5,
      nucleusndxcode: 7,
      insertion_status: 9,
      day_of_week: 9,
      publication_days: 7,
      material_deadline_range: 3,
      insertion_format: 4,
      circulation: 10,
      total_events: 4,
    };
    const keys = Object.keys(countFields);
    setBlocking(true);
    const temp = [];
    if (["refid", "channel", "segment"].includes(e.target.name)) {
      if (
        (e.target.name === "channel" || e.target.name === "segment") &&
        e.target?.value?.value?.toString()?.length > 0
      )
        setError((prev) => ({ ...prev, [e.target.name]: "" })); 
    }
    if (e.target.name === "hit") {
      setError((prev) => ({ ...prev, [e.target.name]: "" }));
    }
    if (keys.includes(e.target.name)) {
      if (e.target.value.length > countFields[e.target.name]) {
        temp.push(e.target.name);
        setError((prev) => ({
          ...prev,
          [e.target.name]: `${e.target.name.charAt(0).toUpperCase() + e.target.name.slice(1)
            } can't be more than ${countFields[e.target.name]} characters`,
        }));
      } else {
        setError((prev) => ({ ...prev, [e.target.name]: "" }));
      }
      setErrorCount(temp);
    }
    if (e.target.name === "channel") {
      setSelectedChannel(e.target.value.value);
      if (
        e.target.value.text === "Acquisition Newspaper" &&
        newSnapState.newspaper_specs.length === 0
      ) {
        setHideLabel(false);
      } else {
        setHideLabel(true);
      }
    }
    if (e.target.name === "newspaper_specs" && e.target.value.length > 0) {
      setHideLabel(true);
    }
    if (
      e.target.name === "insertion_date" ||
      e.target.name === "material_due_date"
    ) {
      setNewSnapState({
        ...newSnapState,
        [e.target.name]:
          e.target.value === null ? "" : new Date(e.target.value),
      });
    } else if (e.target.name === "hit") {
      setNewSnapState({
        ...newSnapState,
        [e.target.name]: Number(e.target.value) || "",
      });
    } else {
      setNewSnapState({ ...newSnapState, [e.target.name]: e.target.value });
    }
  };
  const handleSaveSnap = () => {
    const madatoryFields = ["refid", "channel", "segment"];
    setBlocking(false);
    let temp = [];
    if (
      newSnapState.channel.text === "Acquisition Newspaper" &&
      newSnapState.newspaper_specs.length === 0
    ) {
      temp.push("newspaper_specs");
    }

    Object.keys(newSnapState).forEach((key) => {
      if (key === "hit") {
        const val = (newSnapState[key] + "").split(".")[1] || 0;

        if (val != 0) {
          temp.push(`${key}`);
          setError((prev) => ({
            ...prev,
            [key]: `The value '${newSnapState[key]}' is not valid for Hit`,
          }));
        }
      }
      if (madatoryFields.includes(key) && newSnapState[key].length === 0) {
        temp.push(`${key}`);
        setError((prevState) => ({
          ...prevState,
          [key]: `${key.charAt(0).toUpperCase() + key.slice(1)
            } field is required`,
        }));
      }

      if(key === "refid"){
        const isInNSPFormatVal =isInNSPFormat(newSnapState[key], key);
        if(!isInNSPFormatVal){
          temp.push(`${key}`);
          setError((prev) => ({ ...prev, [key]: "Invalid format! The input should be in the format NSP_<number>." }));
        }
      }
    });

    if (temp?.length === 0 && errorCount?.length === 0) {
      callCreateSnapAPI();
    }
  };
  const callCreateSnapAPI = async () => {
    try {
      let newModifiedJobs = { ...modifiedObj };
      if (modifiedObj?.refid) {
          newModifiedJobs.refid = formatNSP(modifiedObj.refid);
      }
      const res = await sotApi[ApiParams.moduleName].add(newModifiedJobs);
      if (res.status === 201) {
        setSnackBarConfig({
          open: true,
          message: "New snap created successfully.",
          severity: "success",
        });
        history.push({
          pathname: `/sourcedocs/snap/`,
        });
      }
    } catch (e) {
      if (e.response?.status === 400 && !!e.response?.data) {
        if (e.response.data?.refid) {
          setError((prevState) => ({
            ...prevState,
            refid: e.response.data?.refid[0],
          }));
        }
      } else {
        setexceptionError("Snap id already exists or Something went wrong!");
        setOpenDialog(true);
      }
    }
  };

  const callGetStates = async (body) => {
    try {
      const res = await api.getRequiredFields(body);
      const data = res.data;
      let states = [];
      data.forEach((item) => {
        states.push({ text: item.displaytext, value: item.lookupvalue });
      });
      setStateArray(states.sort((a, b) => compareStr(a.text, b.text)));
    } catch (e) {
      alert("Something went wrong!");
    }
  };
  return (
    <>
      <Stack direction="row" spacing={1}>
        <Button variant="outlined" className="button" data-testid="button-save" onClick={handleSaveSnap}>
          Save
        </Button>
      </Stack>

      <Box
        pt={3}
        className={`newgeography - wrapper ${styles.sourceDocsContainer}`}
      >
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                RefId
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="refid"
                id="refid"
                data-testid="button-change"
                value={newSnapState.refid}
                onChange={handleChange}
              />
              <div>
                <label style={{ color: "red" }}>{error.refid}</label>
              </div>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Nucleusndx Code
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="nucleusndxcode"
                id="nucleusndxcode"
                value={newSnapState.nucleusndxcode}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.nucleusndxcode}</label>
            </div>
          </Grid>

          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Channel
              </label>
            </div>
            <div>
              <DropDownList
                name="channel"
                id="channel"
                className={styles.dropDownList}
                data={channelArray}
                textField="text"
                dataItemKey="value"
                onChange={handleChange}
                defaultItem={{ text: "Select Channel-----" }}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.channel}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">Insertion Date</label>
            </div>
            <div>
              <DatePicker
                className={styles.inputText}
                name="insertion_date"
                id="insertion_date"
                format="yyyy-MM-dd"
                value={newSnapState.insertion_date}
                onChange={handleChange}
                popup={CustomPopup}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.insertion_date}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Segment
              </label>
            </div>
            <div>
              <DropDownList
                name="segment"
                id="segment"
                className={styles.dropDownList}
                onChange={handleChange}
                data={segmentArray}
                value={newSnapState.segment || null}
                textField="text"
                dataItemKey="value"
                defaultItem={{ text: "Select Segment-----" }}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.segment}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Day of Week</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="day_of_week"
                id="day_of_week"
                value={newSnapState.day_of_week}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.day_of_week}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">Hit</label>
            </div>
            <div>
              <NumericTextBox
                className={styles.inputText}
                step={1}
                min={0}
                name="hit"
                id="hit"
                value={newSnapState.hit}
                onChange={handleChange}
                format="n2"
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.hit}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Publication Days
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="publication_days"
                id="publication_days"
                value={newSnapState.publication_days}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.publication_days}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>State</label>
            </div>
            <div>
              <DropDownList
                name="state"
                id="state"
                className={styles.dropDownList}
                data={stateArray}
                onChange={handleChange}
                textField="text"
                dataItemKey="value"
                defaultItem={{ text: "Select State-----" }}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">Material Deadline Range</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="material_deadline_range"
                id="material_deadline_range"
                value={newSnapState.material_deadline_range}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>
                {error.material_deadline_range}
              </label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>County</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="county"
                id="county"
                value={newSnapState.county}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Material Due Date
              </label>
            </div>
            <div>
              <DatePicker
                className={styles.inputText}
                name="material_due_date"
                id="material_due_date"
                format={"yyyy-MM-dd"}
                value={newSnapState.material_due_date}
                onChange={handleChange}
                popup={CustomPopup}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.material_due_date}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">FIPS</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="fips"
                id="fips"
                value={newSnapState.fips}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.fips}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Insertion Format
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="insertion_format"
                id="insertion_format"
                value={newSnapState.insertion_format}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.insertion_format}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">Dominant St.County</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="dominant_st_county"
                id="dominant_st_county"
                value={newSnapState.dominant_st_county}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Paper Type</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="paper_type"
                id="paper_type"
                value={newSnapState.paper_type}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Dominant FIPS
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="dominant_fips"
                id="dominant_fips"
                value={newSnapState.dominant_fips}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">Circulation</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="circulation"
                id="circulation"
                value={newSnapState.circulation}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.circulation}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Seminar Flag</label>
            </div>
            <div>
              <DropDownList
                name="seminarflag"
                id="seminarflag"
                className={styles.dropDownList}
                value={newSnapState.seminarflag || null}
                data={[
                  {
                    text: "No",
                    value: "N",
                  },
                  {
                    text: "RSVP",
                    value: "R",
                  },
                  {
                    text: "Yes",
                    value: "Y",
                  },
                ]}
                textField="text"
                onChange={handleChange}
                defaultItem={{ text: "Select-----" }}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Total Events</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="total_events"
                id="total_events"
                value={newSnapState.total_events}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.total_events}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">Insertion Status</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="insertion_status"
                id="insertion_status"
                value={newSnapState.insertion_status}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.insertion_status}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label
                className={`${newSnapState?.channel?.text === "Acquisition Newspaper" &&
                  newSnapState?.newspaper_specs?.length === 0
                  ? "font required"
                  : "font"
                  }`}
              >
                Newspaper Specs
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="newspaper_specs"
                id="newspaper_specs"
                value={newSnapState.newspaper_specs}
                onChange={handleChange}
              />
            </div>
            {!hideLabel && (
              <div>
                <label style={{ color: "red" }}>
                  Newspaper Specs is required if Channel is "NewsPaper"
                </label>
              </div>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Budget Source
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="budget_source"
                id="budget_source"
                value={newSnapState.budget_source}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Provider Code
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="provider_code"
                id="provider_code"
                value={newSnapState.provider_code}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Brand</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="brand"
                id="brand"
                value={newSnapState.brand}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Publication Language
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="publication_language"
                id="publication_language"
                value={newSnapState.publication_language}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Publication</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="publication"
                id="publication"
                value={newSnapState.publication}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Template Code
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="template_code"
                id="template_code"
                value={newSnapState.template_code}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Publication Abbrevation
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="publication_abbreviation"
                id="publication_abbreviation"
                value={newSnapState.publication_abbreviation}
                onChange={handleChange}
              />
            </div>
          </Grid>
        </Grid>
        <PromptAlert isBlocking={isBlocking} />
      </Box>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        className="importTfn-dialog"
        PaperProps={{
          sx: {
            position: "fixed",
            top: 0,
            left: "auto",
            m: 0,
            padding: "10px",
            width: 600,
          },
        }}
      >
        <div className="dialog-import-tfn">
          <div className="content">
            <h2>
              <InfoRoundedIcon /> Something went wrong
            </h2>
            <p style={{ paddingLeft: 10 }}>
              {
                //  exceptionError.map(item=> <li>{item}</li>)
                exceptionError
              }
            </p>
          </div>
        </div>
        <DialogActions>
          <Button
            className="import-dialog-button"
            variant="default"
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Form;
