import {axiosInstance} from "../axios.js";

const accountApi = {

  publishDigitalOutput:{
    getAllChannels:()=>{
      return axiosInstance.get(`/api/account/admin/publish-digital-output/channels/`)
},
    publisDigitalOutPut:(payload)=>{
      return axiosInstance.post(`/api/account/admin/publish-digital-output/publish/`,payload)
    }
  },
  viewModalData :{
    getAll:(body)=>{
      return axiosInstance.put(`/api/account/admin/digital-targeting/view-model-data/query/?limit=${body.limit}&offset=${body.offset}`,body.payload)
    },
    update:(body,id) =>{
      return axiosInstance.patch(`/api/account/admin/digital-targeting/view-model-data/${id}/`,body)
}
  },
  leadZipFlags: {
    getAll: (payload) => {
      return axiosInstance.put(
        `/api/account/admin/digital-targeting/manage-fields/query/`,
        payload)
    },
  },
  manageFields: {
    getAll: (body) => {
      return axiosInstance.put(
        `/api/account/admin/digital-targeting/manage-fields/query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload)
    },
    add: (body) => {
      return axiosInstance.post(`/api/account/admin/digital-targeting/manage-fields/`, body)
    },
    update: (body, id) => {
      return axiosInstance.patch(`/api/account/admin/digital-targeting/manage-fields/${id}/`, body)
    },
    getManageFieldsById: (userId) => {
      return axiosInstance.get(`/api/account/admin/digital-targeting/manage-fields/${userId}/`)
    }
  },
  roles: {
    getAll: (body) => {
      return axiosInstance.put(
        `/api/account/admin/roles/query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload
      );
    },
    getData: (userId) => {
      return axiosInstance.get(
        `/api/account/admin/roles/${userId}`,
      );
    },
    delete: (body) => {
      return axiosInstance.post(
        `/api/account/admin/roles/inactivate/`,
        body
      );
    },
    add: (body) => {
      return axiosInstance.post(`/api/account/admin/roles/`, body)
    },
    update: (body, roleId) => {
      return axiosInstance.patch(`/api/account/admin/roles/${roleId}/`, body)
    },
    addRoleUser: (body) => {
      return axiosInstance.post(`/api/account/admin/users/add-role-users/`, body)
    },
    deleteRoleUser: (body) => {
      return axiosInstance.post(`/api/account/admin/users/del-role-users/`, body)
    },
    getAvailableRoleUsers: (roleId) => {
      return `/api/account/admin/users/${roleId}/get-available-role-users/`
    },
    getRoleUsers: (body, roleId) => {
      return axiosInstance.put(`/api/account/admin/users/${roleId}/get-role-users/`, body)
    },
  },
  users: {
    getAll: (body) => {
      return axiosInstance.put(
        `/api/account/admin/users/query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload
      );
    },
    getData: (userId) => {
      return axiosInstance.get(
        `/api/account/admin/users/${userId}`,
      );
    },
    delete: (body) => {
      return axiosInstance.post(
        `/api/account/admin/users/inactivate/`,
        body
      );
    },
    export: (body) => {
      return axiosInstance.put(
        `/api/account/admin/users/export/`,
        body.payload,
        {
          responseType: 'blob',
        }
      );
    },
    getGroupUsers: (body, groupId) => {
      return axiosInstance.put(
        `/api/account/admin/users/${groupId}/get-group-users/`, body
      );
    },
    addUserGeographies: (body) => {
      return axiosInstance.post(`/api/account/admin/geography/add-user-geography/`, body);
    },
    deleteUserGeographies: (body) => {
      return axiosInstance.post(`/api/account/admin/geography/del-user-geography/`, body);
    },
    addUserRole: (body) => {
      return axiosInstance.post(`/api/account/admin/roles/add-user-roles/`, body)
    },
    deleteUserRole: (body) => {
      return axiosInstance.post(`/api/account/admin/roles/del-user-roles/`, body)
    },

    addUserGroup: (body) => {
      return axiosInstance.post(`/api/account/admin/groups/add-groups/`, body)
    },
    deleteUserGroup: (body) => {
      return axiosInstance.post(`/api/account/admin/groups/del-user-groups/`, body)
    },

    addSecurityLabel: (body) => {
      return axiosInstance.post(`/api/account/admin/securitylabels/add_user_security_label/`, body)
    },
    deleteSecurityLabel: (body) => {
      return axiosInstance.post(`/api/account/admin/securitylabels/del_user_security_label/`, body)
    },
    getUserAvailableGeography: (userId) => {
      return `/api/account/admin/geography/${userId}/get-available-user-geography/`
    },

    getUserGroups: (body, userId) => {
      return axiosInstance.put(`/api/account/admin/groups/${userId}/get-user-groups/`, body)
    },
    getAvailableUserGroups: (userId) => {
      return `/api/account/admin/groups/${userId}/get-available-groups/`
    },
    getUserAvailableRoles: (userId) => {
      return `/api/account/admin/roles/${userId}/get-available-roles/`
    },
    getUserSecurityLabelList: (body, userId) => {
      return axiosInstance.put(`/api/account/admin/securitylabels/${userId}/get-user-security-label/`, body)
    },
    getUserEffectiveSecurityLabelList: (body, userId) => {
      return axiosInstance.put(`/api/account/admin/securitylabels/${userId}/get-effective-security-label/`, body)
    },
    getUserAvailableSecurityLabels: (userId) => {
      return `/api/account/admin/securitylabels/${userId}/get-available-security-label/`
    },
    getUserGeographyList: (body, userId) => {
      return axiosInstance.put(`/api/account/admin/geography/${userId}/get-user-geography/`, body)
    },
    setNewPassword: (body) => {
      return axiosInstance.put(`/api/account/password/change/`, body)
    },
    getUserRoleList: (body, userId) => {
      return axiosInstance.put(`/api/account/admin/roles/${userId}/get-user-roles/`, body)
    },
  },
  groups: {
    getAll: (body) => {
      return axiosInstance.put(
        `/api/account/admin/groups/custom-query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload
      );
    },
    getData: (groupId) => {
      return axiosInstance.get(
        `/api/account/admin/groups/${groupId}`,
      );
    },
    delete: (groupId) => {
      return axiosInstance.delete(
        `/api/account/admin/groups/${groupId}`
      );
    },
    deleteAll: (groupIds) => {
      return axiosInstance.post(
        `/api/account/admin/groups/delete/`,
        {group_id_list: groupIds}
      );
    },
    export: (body) => {
      return axiosInstance.put(
        `/api/account/admin/groups/export/`,
        body.payload,
        {
          responseType: 'blob',
        }
      );
    },
    add: (body) => {
      return axiosInstance.post(`/api/account/admin/groups/`, body)
    },
    addGroupUser: (body) => {
      return axiosInstance.post(`/api/account/admin/groups/add-group-users/`, body)
    },
    deleteGroupUser: (body) => {
      return axiosInstance.post(`/api/account/admin/groups/del-group-users/`, body)
    },
    addGroupSecurityLabel: (body) => {
      return axiosInstance.post(`/api/account/admin/securitylabels/add_group_security_label/`, body)
    },
    getAvailableGroupUsers: (groupId) => {
      return `/api/account/admin/users/${groupId}/get-available-group-users/`
    },
    getGroupSecurityLabels: (body, groupId) => {
      return axiosInstance.put(`/api/account/admin/securitylabels/${groupId}/get-group-security-label/`,
        body);
    },
    getAvailableGroupSecurityLabel: (groupId) => {
      return `/api/account/admin/securitylabels/${groupId}/get-available-group-security-label/`
    },
    deleteGroupSecurityLabel: (body) => {
      return axiosInstance.post(`/api/account/admin/securitylabels/del_group_security_label/`, body)
    },
    update: (groupId, body) => {
      return axiosInstance.patch(`/api/account/admin/groups/${groupId}/`, body)
    },
    updateGroupUserPermission: (body) => {
      return axiosInstance.put('/api/account/admin/groups/update-group-user-permission/', body)
    }
  },
  lookups: {
    getCategories: (body) => {
      const filter = [
        ...body.payload.filter,
        {
          "field": "category",
          "criteria": [
            {
              "op": "is",
              "value": "zlookups"
            }]
        }]
      return axiosInstance.put(
        `/api/common/lookups/query/?limit=${body.limit}&offset=${body.offset}`,
        {...body.payload, filter}
      );
    },
    getData: (lookupId) => {
      return axiosInstance.get(
        `/api/common/lookups/${lookupId}`,
      );
    },
    add: (body) => {
      return axiosInstance.post(`/api/common/lookups/`, body)
    },
    update: (body, lookupId) => {
      return axiosInstance.patch(`/api/common/lookups/${lookupId}/`, body)
    },
    delete: (lookupId) => {
      return axiosInstance.delete(
        `/api/common/lookups/${lookupId}`
      );
    },
    deleteAll: (lookupIds) => {
      return axiosInstance.post(
        `/api/common/lookups/del-lookups/`,
        {lookup_id_list: lookupIds}
      );
    },
    getValues: (body) => {
      const filter = [
        ...body.payload.filter,
        {
          "field": "category ",
          "criteria": [
            {
              "op": "is not",
              "value": "zLookups"
            },
            {
              "op": "and"
            },
            {
              "op": "is not",
              "value": "zLookupType"
            }
          ]
        }
      ]
      return axiosInstance.put(
        `/api/common/lookups/query/?limit=${body.limit}&offset=${body.offset}`,
        {...body.payload, filter}
      );
    },
    getTypes: (body) => {
      const filter = [
        ...body.payload.filter,
        {
          "field": "category",
          "criteria": [
            {
              "op": "contains",
              "value": "zLookupType"
            }]
        }
      ]
      return axiosInstance.put(
        `/api/common/lookups/query/?limit=${body.limit}&offset=${body.offset}`,
        {...body.payload, filter}
      );
    },

    getDropDownTypes: () => {
      const filter = [
        {
          "field": "category",
          "criteria": [
            {
              "op": "contains",
              "value": "zLookupType"
            }]
        }
      ]
      return axiosInstance.put(
        `/api/common/lookups/query/?no_page`,
        {filter}
      );
    },
    getDropDownCategories: () => {
      const filter = [
        {
          "field": "category",
          "criteria": [
            {
              "op": "is",
              "value": "zlookups"
            }]
        }]
      return axiosInstance.put(
        `/api/common/lookups/query/?no_page`,
        {filter}
      );
    },
    getNotificationTypes: (body) => {
      return axiosInstance.put(
        `/api/common/notification-type/query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload
      );
    },
    deleteNotificationTypes: (lookupIds) => {
      return axiosInstance.post(
        `/api/common/notification-type/del-noti-type/`,
        {notifytype_id_list: lookupIds}
      );
    },
    addNotificationType: (body) => {
      return axiosInstance.post(`/api/common/notification-type/`, body)
    },
    updateNotificationType: (body, lookupId) => {
      return axiosInstance.patch(`/api/common/notification-type/${lookupId}/`, body)
    },
    getSnippets: (body) => {
      return body ? axiosInstance.put(
        `/api/common/snippet/query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload
      ) : axiosInstance.put(`/api/common/snippet/query/?no_page`);
    },
    deleteSnippets: (lookupIds) => {
      return axiosInstance.post(
        `/api/common/snippet/del-snippet/`,
        {snippet_id_list: lookupIds}
      );
    },
    addSnippet: (body) => {
      return axiosInstance.post(`/api/common/snippet/`, body)
    },
    updateSnippet: (body, lookupId) => {
      return axiosInstance.patch(`/api/common/snippet/${lookupId}/`, body)
    },
    getTooltips: (body) => {
      return axiosInstance.put(
        `/api/common/tooltip/query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload
      );
    },
    getTooltipFromCategoryAndSubcategory: (category, subCategory) => {
      return axiosInstance.put(
        `/api/common/tooltip/query/?no_page`,
        {
          filter: [
            {
              field: 'category',
              criteria: [{
                op: "is",
                value: category
              }]
            },
            {
              field: 'subcategory',
              criteria: [{
                op: "is",
                value: subCategory
              }]
            }
          ]
        }
      );
    },
    deleteTooltips: (lookupIds) => {
      return axiosInstance.post(
        `/api/common/tooltip/del-tooltip/`,
        {tooltip_id_list: lookupIds}
      );
    },
    addTooltip: (body) => {
      return axiosInstance.post(`/api/common/tooltip/`, body)
    },
    updateTooltip: (body, lookupId) => {
      return axiosInstance.patch(`/api/common/tooltip/${lookupId}/`, body)
    },
    downloadTooltip: () => {
      return axiosInstance.get(`/api/common/tooltip/download/`, {
        responseType: "blob"
      })
    },
  },
  tfnMediaChannelApi : {
    upload: (body) => {
      return axiosInstance.post(
        `/api/tfn/channelsubchannel/import/`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    download: () => {
      return axiosInstance.get(`/api/tfn/channelsubchannel/download/`, {
        responseType: "blob",
      });
    },
  }
};

export default accountApi;
